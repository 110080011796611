import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['startInput', 'endInput']

  syncToStart() {
    if (this.#needSync()) {
      this.endInputTarget.value = this.startInputTarget.value
    }
  }

  syncToEnd() {
    if (this.#needSync()) {
      this.startInputTarget.value = this.endInputTarget.value
    }
  }

  #needSync() {
    const start = this.startInputTarget.value
    const end = this.endInputTarget.value
    return start && end && end < start
  }
}
