import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select']

  fire() {
    for (const el of this.selectTargets) {
      this._autoSelect(el)
    }
  }

  _autoSelect(selectEl) {
    const value = selectEl.dataset.autoSelectValue
    if (!value) {
      return
    }

    const option = Array.from(selectEl).find(opt => opt.value === value)
    if (option) {
      option.selected = true
    }
  }
}
