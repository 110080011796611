import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  update(ev) {
    const { currentTarget } = ev
    const target = document.getElementById(currentTarget.dataset.updateTarget)
    if (!target) {
      return
    }

    this.#updateOptions(currentTarget, target)
    target.dispatchEvent(new Event('change'))
  }

  #updateOptions(source, dest) {
    const selectedOption = source.selectedOptions[0]
    if (!selectedOption) { return }

    this.#removeAll(dest)

    const { options } = selectedOption.dataset
    if (!options) { return }

    const newOptions = this.#buildOptions(JSON.parse(options))
    for (const opt of newOptions) {
      dest.appendChild(opt)
    }
  }

  #buildOptions(options) {
    return options.map((option) => {
      const [label, value, { data }] = option
      const optEl = document.createElement('option')
      optEl.value = value
      optEl.textContent = label
      if (data) {
        for (const [k, v] of Object.entries(data)) {
          optEl.setAttribute(`data-${k}`, v)
        }
      }
      return optEl
    })
  }

  #removeAll(select) {
    while (select.options.length > 0) {
      select.remove(0)
    }
  }
}
