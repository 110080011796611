import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox'];

  connect = () => {
    this.lastChecked = null;
  };

  check = (event) => {
    if (event.shiftKey && this.lastChecked) {
      const targetCheckboxes =
        this.getCheckboxesBetweenLastAndCurrentChecked(
          this.lastChecked,
          event.currentTarget
        );

      targetCheckboxes.forEach((checkbox) => {
        checkbox.checked = this.lastChecked.checked;
      });
    }
    this.lastChecked = event.currentTarget;
  };

  getCheckboxesBetweenLastAndCurrentChecked = (last, current) => {
    const lastCheckedIndex = this.checkboxTargets.indexOf(last);
    const currentChekedIndex = this.checkboxTargets.indexOf(current);
    if (lastCheckedIndex === currentChekedIndex) return [];

    const start = Math.min(lastCheckedIndex, currentChekedIndex)
    const end = Math.max(lastCheckedIndex, currentChekedIndex)
    return this.checkboxTargets.slice(start, end + 1);
  };
}
