import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['subjectInput', 'bodyInput', 'preview']
  static values = {
    url: String
  }

  connect() {
    this.intersectionObserver = new IntersectionObserver(this.handleIntersection)
    this.intersectionObserver.observe(this.previewTarget)
  }

  disconnect() {
    this.intersectionObserver.disconnect()
  }

  handleIntersection = async (entries) => {
    const [entry] = entries
    if (!entry || !entry.isIntersecting) { return }

    const response = await this._requestPreview()
    const text = await response.text()
    this._render(text)
  }

  async _requestPreview() {
    const data = new FormData()
    data.append('subject', this.subjectInputTarget.value)
    data.append('body', this.bodyInputTarget.value)
    return fetch(this.urlValue, {
      method: 'post',
      body: data
    })
  }

  _render(text) {
    this.previewTarget.innerHTML = text
  }
}
