import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'countMessage']

  toggle(ev) {
    const checked = ev.currentTarget.checked
    for (const checkbox of this.checkboxTargets) {
      checkbox.checked = checked
    }
    this.updateCountMessage()
  }

  updateCountMessage() {
    if (!this.hasCountMessageTarget) {
      return
    }

    const count = this._checkedCount()
    this.countMessageTarget.textContent = count > 0 ? `${count}件選択しました。` : ''
  }

  _checkedCount() {
    return Array.from(this.checkboxTargets).filter(el => el.checked).length
  }
}
